import {request, useAxios} from "../request";
import {useMutation} from "react-query";

export const REMOTE_CONTROL = {
    SIGNAL_EDIT: {
        name: "Signal edit",
        url: "/signal_edit/"
    },
    DOSING_PUMP: {
        name: "Dosing pump",
        url: "/dosing_pump/"
    },
    MEASUREMENT: {
        name: "Measurement",
        url: "/measurement/"
    },
    DEVICE_CONTROL: {
        name: "Device control",
        url: "/device_control/"
    }
}

export function useGetSignalEdit(unit_id) {
    return useAxios(
        [REMOTE_CONTROL.SIGNAL_EDIT.url, unit_id],
        {
            url: `${REMOTE_CONTROL.SIGNAL_EDIT.url}${unit_id}/`,
            method: "GET",
        },
        {enabled: true},
    )
}

export const useEditSignalEdit = () => useMutation( ({ unit_id, data }) => {
    return request({
        url: `${REMOTE_CONTROL.SIGNAL_EDIT.url}${unit_id}/`,
        method: "PATCH",
        data: {...data}
    })
})

export function useGetDosingPumps(unitId) {
    return useAxios(
        [REMOTE_CONTROL.DOSING_PUMP.url, unitId],
        {
            url: REMOTE_CONTROL.DOSING_PUMP.url,
            params: {
                unit: unitId,
            },
            method: "GET",
        },
        {enabled: true},
    )
}

export function useGetDosingPump(id) {
    return useAxios(
        [REMOTE_CONTROL.DOSING_PUMP.url, id],
        {
            url: `${REMOTE_CONTROL.DOSING_PUMP.url}${id}/`,
            method: "GET",
        },
        {enabled: true},
    )
}

export const useEditDosingPump = () => useMutation( ({ id, data }) => {
    return request({
        url: `${REMOTE_CONTROL.DOSING_PUMP.url}${id}/`,
        method: "PATCH",
        data: {...data}
    })
})

export function useGetMeasurement(unit_id) {
    return useAxios(
        [REMOTE_CONTROL.MEASUREMENT.url, unit_id],
        {
            url: `${REMOTE_CONTROL.MEASUREMENT.url}${unit_id}/`,
            method: "GET",
        },
        {enabled: true},
    )
}

export const useEditMeasurement = () => useMutation( ({ unit_id, data }) => {
    return request({
        url: `${REMOTE_CONTROL.MEASUREMENT.url}${unit_id}/`,
        method: "PATCH",
        data: {...data}
    })
})

export function useGetDeviceControls(unitId) {
    return useAxios(
        [REMOTE_CONTROL.DEVICE_CONTROL.url, unitId],
        {
            url: REMOTE_CONTROL.DEVICE_CONTROL.url,
            params: {
                unit: unitId,
            },
            method: "GET",
        },
        {enabled: true},
    )
}

export const useCreateDeviceControl = () => useMutation( (data) => {
    return request({
        url: REMOTE_CONTROL.DEVICE_CONTROL.url,
        method: "POST",
        data: data
    })
})

export function useGetDeviceControl(id) {
    return useAxios(
        [REMOTE_CONTROL.DEVICE_CONTROL.url, id],
        {
            url: `${REMOTE_CONTROL.DEVICE_CONTROL.url}${id}/`,
            method: "GET",
        },
        {enabled: true},
    )
}

export const useEditDeviceControl = () => useMutation( ({ id, data }) => {
    return request({
        url: `${REMOTE_CONTROL.DEVICE_CONTROL.url}${id}/`,
        method: "PATCH",
        data: {...data}
    })
})