import {
    makeStyles,
    Box,
    Typography,
    Button,
    Switch,
} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import EnterPasswordModal from "../modals/EnterPasswordModal"
import {useGetMeasurement, useEditMeasurement} from "../../../utils/api/remote_control";

const useStyles = makeStyles(() => ({
    lineToSeparate: {
        borderBottom: '1px solid #ddd',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '40vh',
    },
    item: {
        marginTop: 10,
        width: 'auto',
        alignSelf:'center'
    },
    switch_track: {
        backgroundColor: "#f50057",
    },
    switch_base: {
        color: "#f50057",
        "&.Mui-disabled": {
            color: "#e886a9"
        },
        "&.Mui-checked": {
            color: "#378a3a"
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#4CAF50",
        }
    },
    switch_primary: {
        "&.Mui-checked": {
            color: "#378a3a",
        },
        "&.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#4CAF50",
        },
    },
    switch_large: {
        width: 68,
        height: 34,
        padding: 7,
        "& .MuiSwitch-switchBase": {
            margin: 1,
            padding: 0,
            transform: "translateX(6px)",
            "&.Mui-checked": {
            transform: "translateX(30px)",
            },
        },
        "& .MuiSwitch-thumb": {
            width: 32,
            height: 32,
        },
        "& .MuiSwitch-track": {
            borderRadius: 20 / 2,
        },
    },
}))

export default function Measurement(props) {
    const [enterPasswordOpen, setEnterPasswordOpen] = useState(false)

    const [measurement_process, setMeasurementProcess] = useState(false)
    
    const classes = useStyles()

    const getMeasurementRequest = useGetMeasurement(props.unit)
        
    const editMeasurementRequest = useEditMeasurement()

    useEffect(()=>{
        if(getMeasurementRequest.isSuccess){
            let data = getMeasurementRequest.data.data
            setMeasurementProcess(data.measurement_process)
        }
        // eslint-disable-next-line
    },[getMeasurementRequest.data])


    const handleEnterPasswordPump = () => {
        setEnterPasswordOpen(true)
    }

    const handleSwitchChange = (event) => {
        setMeasurementProcess(event.target.checked)
    }

    const handlePasswordSubmit = (password) => {
        const data = {
            measurement_process: measurement_process,
            password: password,
        }
        editMeasurementRequest.mutateAsync({
            unit_id: props.unit,
            data: data
        }).then(()=>{
        }).catch((err)=>{
            console.log(err)
        })
    }

    return (
        <>
            <Box className={classes.form}>
                <Typography
                    variant="h6"
                    className={`${classes.item} ${classes.lineToSeparate}`}
                    align='center'>
                    Measurement process
                </Typography>
                <Switch
                    classes={{
                        track: classes.switch_track,
                        switchBase: classes.switch_base,
                        colorPrimary: classes.switch_primary,
                        root: classes.switch_large,
                    }}
                    color={false ? "primary" : "default"}
                    onChange={handleSwitchChange}
                    className={classes.item}
                    checked={measurement_process}
                />
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.item}
                    onClick={handleEnterPasswordPump}
                >
                    Edit
                </Button>
            </Box>

            {enterPasswordOpen && (
                <EnterPasswordModal
                    open={enterPasswordOpen}
                    handleClose={() => {
                        setEnterPasswordOpen(false)
                    }}
                    onSubmit={handlePasswordSubmit}
                    action="CHANGE"
                    text_first_line="Pre zmenu stavu merania procesu"
                    text_third_line="For change"
                />
            )}
        </>
    )
}
