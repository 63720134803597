import {
    Grid
} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import DosingPump from "./DosingPump"
import {useGetDosingPumps} from "../../../utils/api/remote_control";

export default function DosingPumps(props) {
    const [dosingPumps, setDosingPumps] = useState([])
    const useGetDosingPumpsRequest = useGetDosingPumps(props.unit)
    
    useEffect(()=>{
        if(useGetDosingPumpsRequest.isSuccess){
            let data = useGetDosingPumpsRequest.data.data
            let sortedData = data.results.sort((a, b) => a.order - b.order)
            setDosingPumps(sortedData)
        }
        // eslint-disable-next-line
    },[useGetDosingPumpsRequest.data])

    const handleRefresh = () => {
        useGetDosingPumpsRequest.refetch()
    };

    return (
        <>
            <Grid
                container
                justifyContent ="flex-end"
                spacing={0}
                direction="row"
            >
                {dosingPumps.map((pump) => (
                    <Grid item xs={3} sm={3} md={3} lg={3} key={pump.order}>
                        <DosingPump 
                            id={pump.id}
                            name={pump.name}
                            mode={pump.mode}
                            ratio={`${pump.parameter_m_value}:${pump.parameter_n_value}`}
                            onRefresh={handleRefresh}
                        />
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
