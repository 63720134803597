import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
    TextField,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import React, {useEffect, useState} from "react"
import {
    dosingPumpModeType,
    dosingPumpActionValue,
    renameType,
} from "../../../../utils/enumMapping"
import {EXPRESSION, useExpressions} from "../../../../utils/api/expressions";
import {useGetDosingPump, useEditDosingPump} from "../../../../utils/api/remote_control";

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 3,
        padding: theme.spacing(2, 4, 3),
    },
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    item: {
        marginTop: 10,
        marginRight: 10,
        width: "calc(50% - 16px)",
    },
    lineToSeparate: {
        borderBottom: '1px solid #ddd',
    },
    passwordInput: {
        position: 'absolute',
        left: theme.spacing(4),
        bottom: theme.spacing(1),
    },
}))

export default function DosingPumpEditModal(props) {
    const classes = useStyles()
    
    // all modes
    const [name, setName] = useState("")
    const [mode, setMode] = useState(renameType(dosingPumpModeType.MANUAL))
    const [targetValue, setTargetValue] = useState("")
    const [password, setPassword] = useState("")
    const [parameterMValue, setParameterMValue] = useState("")
    const [parameterNValue, setParameterNValue] = useState("")

    // automatic mode
    const [analogExpression, setAnalogExpression] = useState("")
    const [toleranceHigh, setToleranceHigh] = useState("")
    const [toleranceLow, setToleranceLow] = useState("")
    const [actionHigh, setActionHigh] = useState(dosingPumpActionValue.M)
    const [actionLow, setActionLow] = useState(dosingPumpActionValue.M)
    const [controlExpression, setControlExpression] = useState("")
    const [controlExpressionValueRange, setControlExpressionValueRange] = useState("")

    // manual mode
    const [parameter, setParameter] = useState()

    // status mode
    const [statusUpdateValue, setStatusUpdateValue] = useState("")

    const [analogExpressions, setAnalogExpressions] = useState([])
    const [analogExpressionsLoaded, setAnalogExpressionsLoaded] = useState(false)

    const analogExpressionsRequest = useExpressions(props.unit, (
            EXPRESSION.ANALOG))

    const getDosingPumpRequest = useGetDosingPump(props.id)
    
    const editDosingPumpRequest = useEditDosingPump()
    
    useEffect(() => {
        if(analogExpressionsRequest.isSuccess){
            let data = analogExpressionsRequest.data.data
            console.log(data)
            setAnalogExpressions(data.results)
            setAnalogExpressionsLoaded(true)
        }
        // eslint-disable-next-line
    },[analogExpressionsRequest.data])

    useEffect(()=>{
        if(getDosingPumpRequest.isSuccess && analogExpressionsLoaded){
            let data = getDosingPumpRequest.data.data
            setName(data.name)
            setMode(data.mode)
            setTargetValue(data.target_value)
            setAnalogExpression(
                analogExpressions.find((x) => x.id === data.analog_expression)
            )
            setToleranceHigh(data.tolerance_high)
            setToleranceLow(data.tolerance_low)
            setActionHigh(data.action_high)
            setActionLow(data.action_low)
            setControlExpression(
                analogExpressions.find((x) => x.id === data.analog_expression) // TODO fix to control expressions
            )
            setControlExpressionValueRange(data.control_expression_value_range)
            setParameter(renameType(data.parameter))
            setParameterMValue(data.parameter_m_value)
            setParameterNValue(data.parameter_n_value)
            setStatusUpdateValue(`${data.parameter_m_value}:${data.parameter_n_value}`)
        }
        // eslint-disable-next-line
    },[getDosingPumpRequest.data, analogExpressionsLoaded])

    const pumpValidation = () => {
        return automaticPumpModeValidation() || manualPumpModeValidation() || statusPumpModeValidation()
    }
    const automaticPumpModeValidation = () => {
        return true;
    }
    const manualPumpModeValidation = () => {
        return true;
    }
    const statusPumpModeValidation = () => {
        return true;
    }

    const textEmptyValidation = () => {
        return name === '' || name == null;
    }
    const statusUpdateValidation = () => {
        return !/^\d+:\d+$/.test(statusUpdateValue);
    }
    const passwordValidation = () => {
        return password === null || password.length !== 4 || !/^\d+$/.test(password);
    }


    const handleSubmit = () => {
        const data = {
            name: name,
            target_value: targetValue,
            analog_expression: analogExpression ? analogExpression["id"] : null,
            tolerance_high: toleranceHigh,
            tolerance_low: toleranceLow,
            action_high: actionHigh,
            action_low: actionLow,
            control_expression_value_range: controlExpressionValueRange,
            parameter: parameter,
            parameter_m_value: parameterMValue,
            parameter_n_value: parameterNValue
        }
        if (mode !== renameType(dosingPumpModeType.STATUS_UPDATE)) {
            data.mode = mode
        }
        editDosingPumpRequest.mutateAsync({
            id: props.id,
            data: data
        }).then(()=>{
            props.handleClose()
        }).catch((err)=>{
            console.log(err)
        })
    }
    
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="scroll-dialog-title">
                Pump edit
            </DialogTitle>
            <DialogContent dividers={true}>
                <form
                    className={classes.root}
                    id="add_expression_form"
                    onSubmit={handleSubmit}
                >
                    <Box>
                        <TextField
                            error={textEmptyValidation()}
                            value={name}
                            required
                            label="Name"
                            className={classes.item}
                            fullWidth
                            onChange={(e) => {
                                setName(e.target.value)
                            }}
                        />
                        <FormControl className={classes.item} fullWidth>
                            <InputLabel>Mode</InputLabel>
                            <Select
                                value={mode}
                                onChange={(e) => {
                                    setMode(e.target.value)
                                }}
                                renderValue={(selected) => renameType(selected)}
                            >
                                {Object.entries(dosingPumpModeType).map(
                                    ([key, item]) => {
                                        return (
                                            <MenuItem key={key} value={key} className={classes.lineToSeparate}>
                                                {item}
                                            </MenuItem>
                                        )
                                    }
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                    {mode === renameType(dosingPumpModeType.AUTOMATIC) && (
                        <>
                            <Box>
                                <FormControl className={classes.item} fullWidth>
                                    <InputLabel>Analog expression</InputLabel>
                                    <Select
                                        value={analogExpression}
                                        onChange={(e) => {
                                            setAnalogExpression(e.target.value)
                                        }}
                                        renderValue={(selected) => selected.name}
                                    >
                                        {analogExpressions.map((item, index) => (
                                            <MenuItem key={`expr_${index}`} value={item} className={classes.lineToSeparate}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <TextField
                                    error={textEmptyValidation()}
                                    value={targetValue}
                                    required
                                    label="Target value"
                                    className={classes.item}
                                    fullWidth
                                    onChange={(e) => {
                                        setTargetValue(e.target.value)
                                    }}
                                />
                            </Box>
                            <Box>
                                <TextField
                                    error={textEmptyValidation()}
                                    value={toleranceHigh}
                                    required
                                    label="Tolerance high"
                                    className={classes.item}
                                    fullWidth
                                    onChange={(e) => {
                                        setToleranceHigh(e.target.value)
                                    }}
                                />
                                <TextField
                                    error={textEmptyValidation()}
                                    value={toleranceLow}
                                    required
                                    label="Tolerance low"
                                    className={classes.item}
                                    fullWidth
                                    onChange={(e) => {
                                        setToleranceLow(e.target.value)
                                    }}
                                />
                            </Box>
                            <Box>
                                <FormControl className={classes.item} fullWidth>
                                    <InputLabel>Action high</InputLabel>
                                    <Select
                                        value={renameType(actionHigh)}
                                        onChange={(e) => {
                                            setActionHigh(e.target.value)
                                        }}
                                        renderValue={(selected) => renameType(selected)}
                                    >
                                        {Object.entries(dosingPumpActionValue).map(
                                            ([key, item]) => {
                                                return (
                                                    <MenuItem key={key} value={key} className={classes.lineToSeparate}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            }
                                        )}
                                    </Select>
                                </FormControl>
                                <FormControl className={classes.item} fullWidth>
                                    <InputLabel>Action low</InputLabel>
                                    <Select
                                        value={renameType(actionLow)}
                                        onChange={(e) => {
                                            setActionLow(e.target.value)
                                        }}
                                        renderValue={(selected) => renameType(selected)}
                                    >
                                        {Object.entries(dosingPumpActionValue).map(
                                            ([key, item]) => {
                                                return (
                                                    <MenuItem key={key} value={key} className={classes.lineToSeparate}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            }
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box>
                                <FormControl className={classes.item} fullWidth>
                                    <InputLabel>Control expression</InputLabel>
                                    <Select
                                        value={controlExpression}
                                        onChange={(e) => {
                                            setControlExpression(e.target.value)
                                        }}
                                        renderValue={(selected) => selected.name}
                                    >
                                        {analogExpressions.map((item, index) => ( // TDDO: change to control expressions
                                            <MenuItem key={`expr_${index}`} value={item} className={classes.lineToSeparate}>
                                                {item.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    error={textEmptyValidation()}
                                    value={controlExpressionValueRange}
                                    required
                                    label="Control expression value range"
                                    className={classes.item}
                                    fullWidth
                                    onChange={(e) => {
                                        setControlExpressionValueRange(e.target.value)
                                    }}
                                />
                            </Box>
                        </>
                    )}
                    {mode === renameType(dosingPumpModeType.MANUAL) && (
                        <Box>
                            <FormControl className={classes.item} fullWidth>
                                <InputLabel>Parameter M or N</InputLabel>
                                <Select
                                    value={renameType(parameter)}
                                    onChange={(e) => {
                                        setParameter(e.target.value)
                                    }}
                                    renderValue={(selected) => renameType(selected)}
                                >
                                    {Object.entries(dosingPumpActionValue).map(
                                        ([key, item]) => {
                                            return (
                                                <MenuItem key={key} value={key} className={classes.lineToSeparate}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        }
                                    )}
                                </Select>
                            </FormControl>
                            <TextField
                                error={textEmptyValidation()}
                                value={targetValue}
                                required
                                label="Target value"
                                className={classes.item}
                                fullWidth
                                onChange={(e) => {
                                    setTargetValue(e.target.value)
                                }}
                            />
                        </Box>
                    )}
                    {mode === renameType(dosingPumpModeType.STATUS_UPDATE) && (
                        <Box>
                            <TextField
                                error={statusUpdateValidation()}
                                value={statusUpdateValue}
                                required
                                label="M:N Status update"
                                className={classes.item}
                                fullWidth
                                onChange={(e) => {
                                    setStatusUpdateValue(e.target.value)
                                    const [firstNumber, secondNumber] = e.target.value.split(':').map(Number);
                                    setParameterMValue(firstNumber)
                                    setParameterNValue(secondNumber)
                                }}
                            />
                        </Box>
                    )}
                </form>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justifyContent="flex-end"
                    spacing={2}
                >
                    {(mode === renameType(dosingPumpModeType.MANUAL) || mode === renameType(dosingPumpModeType.AUTOMATIC)) && (
                        <TextField
                            error={passwordValidation()}
                            value={password}
                            type="number"
                            required
                            label="Password"
                            className={classes.passwordInput}
                            onChange={(e) => {
                                setPassword(e.target.value)
                            }}
                        />
                    )}
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={!pumpValidation()}
                            variant="contained"
                            color={!pumpValidation() ? 'info' : 'primary'}
                            onClick={handleSubmit}
                        >
                            Edit
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
