import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Grid
} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import React, {useState} from "react"
import {useCreateDeviceControl, useEditDeviceControl} from "../../../../utils/api/remote_control";
import EnterPasswordModal from "../../modals/EnterPasswordModal"

const useStyles = makeStyles((theme) => ({
    item: {
        marginTop: 10,
        marginRight: 10,
    },
}))

export default function DeviceControlEditModal(props) {
    const classes = useStyles()
    
    const [enterPasswordOpen, setEnterPasswordOpen] = useState(false)

    const [name, setName] = useState(props.name || "")
    const [substituteForDO2, setSubstituteForDO2] = useState(props.substituteForDO2 || "")
    const [substituteForDO3, setSubstituteForDO3] = useState(props.substituteForDO3 || "")
    
    const createDosingPumpRequest = useCreateDeviceControl()
    const editDosingPumpRequest = useEditDeviceControl()
    
    const deviceControlValidation = () => {
        return nameValidation() || setSubstituteForDO2Validation() || setSubstituteForDO3Validation()
    }
    const nameValidation = () => {
        return name === '' || name == null;
    }
    const setSubstituteForDO2Validation = () => {
        return substituteForDO2 === '' || substituteForDO2 == null;
    }
    const setSubstituteForDO3Validation = () => {
        return substituteForDO3 === '' || substituteForDO3 == null;
    }

    const handlePasswordSubmit = (password) => {
        const data = {
            name: name,
            substitute_for_do2: substituteForDO2,
            substitute_for_do3: substituteForDO3,
            password: password,
        }
        if (props.crudType === 'create') {
            data.unit = props.unit
            data.position = props.position
            createDosingPumpRequest.mutateAsync(data).then(()=>{
                props.handleClose()
            }).catch((err)=>{
                console.log(err)
            })
        }
        if (props.crudType === 'update') {
            editDosingPumpRequest.mutateAsync({
                id: props.id,
                data: data
            }).then(()=>{
                props.handleClose()
            }).catch((err)=>{
                console.log(err)
            })
        }
    }
    
    return (
        <>
            <Dialog
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle id="scroll-dialog-title">
                    Device
                </DialogTitle>
                <DialogContent dividers={true}>
                    <Grid
                        container
                        justifyContent ="flex-end"
                        spacing={0}
                        direction="column"
                        alignItems="center"
                    >
                        <TextField
                            error={nameValidation()}
                            value={name}
                            required
                            label="Name"
                            className={classes.item}
                            fullWidth
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            error={setSubstituteForDO2Validation()}
                            value={substituteForDO2}
                            required
                            label="Substitute for DO2 = +M/N"
                            className={classes.item}
                            fullWidth
                            onChange={(e) => setSubstituteForDO2(e.target.value)}
                        />
                        <TextField
                            error={setSubstituteForDO3Validation()}
                            value={substituteForDO3}
                            required
                            label="Substitute for DO3 = -M/N"
                            className={classes.item}
                            fullWidth
                            onChange={(e) => setSubstituteForDO3(e.target.value)}
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        justifyContent="flex-end"
                        spacing={2}
                    >
                        <Grid item>
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={props.handleClose}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                disabled={deviceControlValidation()}
                                variant="contained"
                                color={deviceControlValidation() ? 'info' : 'primary'}
                                onClick={() => setEnterPasswordOpen(true)}
                            >
                                {props.crudType === 'create' ? 'Add' : 'Edit'} device
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            {enterPasswordOpen && (
                <EnterPasswordModal
                    open={enterPasswordOpen}
                    handleClose={() => {
                        setEnterPasswordOpen(false)
                    }}
                    onSubmit={handlePasswordSubmit}
                    action="EDIT"
                    text_first_line="Pre potvrdenie akcie"
                    text_third_line="For this action you must"
                />
            )}
        </>
    )
}
