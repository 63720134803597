import {
    makeStyles,
    Grid
} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import DeviceControl from "./DeviceControl"
import EmptyDeviceControl from "./EmptyDeviceControl"
import {useGetDeviceControls} from "../../../utils/api/remote_control";
import {
    deviceControlPosition,
} from "../../../utils/enumMapping"

const useStyles = makeStyles(() => ({
    grid: {
        alignItems: 'center',
        height: '40vh',
    },
}))

export default function DeviceControls(props) {
    const classes = useStyles()

    const [deviceControl1, setDeviceControl1] = useState()
    const [deviceControl2, setDeviceControl2] = useState()
    const [deviceControl3, setDeviceControl3] = useState()
    const [deviceControl4, setDeviceControl4] = useState()

    const useGetDeviceControlsRequest = useGetDeviceControls(props.unit)
    
    useEffect(()=>{
        if(useGetDeviceControlsRequest.isSuccess){
            let data = useGetDeviceControlsRequest.data.data
            const deviceControl1 = data.results.find(device => device.position === deviceControlPosition.TWO_X_Q)
            setDeviceControl1(deviceControl1)
            const deviceControl2 = data.results.find(device => device.position === deviceControlPosition.THREE_X_Q)
            setDeviceControl2(deviceControl2)
            const deviceControl3 = data.results.find(device => device.position === deviceControlPosition.FOUR_X_Q)
            setDeviceControl3(deviceControl3)
            const deviceControl4 = data.results.find(device => device.position === deviceControlPosition.FIVE_X_Q)
            setDeviceControl4(deviceControl4)
        }
        // eslint-disable-next-line
    },[useGetDeviceControlsRequest.data])

    const handleRefresh = () => {
        useGetDeviceControlsRequest.refetch()
    };

    return (
        <>
            <Grid
                container
                justifyContent ="flex-end"
                spacing={0}
                direction="row"
                className={classes.grid}
            >
                <Grid item xs={3} sm={3} md={3} lg={3}>
                    {deviceControl1 ? (
                        <DeviceControl 
                            id={deviceControl1.id}
                            name={deviceControl1.name}
                            substitute_for_do2={deviceControl1.substitute_for_do2}
                            substitute_for_do3={deviceControl1.substitute_for_do3}
                            onRefresh={handleRefresh}
                        />
                    ) : (
                        <EmptyDeviceControl
                            unit={props.unit}
                            position={deviceControlPosition.TWO_X_Q}
                            onRefresh={handleRefresh}
                        />
                    )}
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                    {deviceControl2 ? (
                        <DeviceControl 
                            id={deviceControl2.id}
                            name={deviceControl2.name}
                            substitute_for_do2={deviceControl2.substitute_for_do2}
                            substitute_for_do3={deviceControl2.substitute_for_do3}
                            onRefresh={handleRefresh}
                        />
                    ) : (
                        <EmptyDeviceControl
                            unit={props.unit}
                            position={deviceControlPosition.THREE_X_Q}
                            onRefresh={handleRefresh}
                        />
                    )}
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                    {deviceControl3 ? (
                        <DeviceControl 
                            id={deviceControl3.id}
                            name={deviceControl3.name}
                            substitute_for_do2={deviceControl3.substitute_for_do2}
                            substitute_for_do3={deviceControl3.substitute_for_do3}
                            onRefresh={handleRefresh}
                        />
                    ) : (
                        <EmptyDeviceControl
                            unit={props.unit}
                            position={deviceControlPosition.FOUR_X_Q}
                            onRefresh={handleRefresh}
                        />
                    )}
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3}>
                    {deviceControl4 ? (
                        <DeviceControl 
                            id={deviceControl4.id}
                            name={deviceControl4.name}
                            substitute_for_do2={deviceControl4.substitute_for_do2}
                            substitute_for_do3={deviceControl4.substitute_for_do3}
                            onRefresh={handleRefresh}
                        />
                    ) : (
                        <EmptyDeviceControl
                            unit={props.unit}
                            position={deviceControlPosition.FIVE_X_Q}
                            onRefresh={handleRefresh}
                        />
                    )}
                </Grid>
            </Grid>
        </>
    )
}
