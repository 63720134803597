import {
    makeStyles,
    Button,
    Typography,
    Grid
} from "@material-ui/core"
import React, {useState} from "react"
import DosingPumpEditModal from "./modals/DosingPumpEditModal"
import dosingPumpImage from "../../../assets/dosing-pump.jpg"
import {
    renameType,
} from "../../../utils/enumMapping"

const useStyles = makeStyles(() => ({
    lineToSeparate: {
        borderBottom: '1px solid #ddd',
    },
    item: {
        marginTop: 10,
        marginRight: 10,
    },
    img: {
        width: "calc(50% - 16px)",
    }
}))

export default function DosingPump(props) {
    const [dosingPumpOpen, setDosingPumpOpen] = useState(false)

    const classes = useStyles()

    const handleOpenDosingPump = () => {
        setDosingPumpOpen(true)
    }

    return (
        <>
            <Grid
                container
                justifyContent ="flex-end"
                spacing={0}
                direction="column"
                alignItems="center"
            >
                <Typography
                    variant="h6"
                    className={`${classes.item} ${classes.lineToSeparate}`}
                    align='center'>
                    {props.name}
                </Typography>
                <img
                    src={dosingPumpImage}
                    height="auto"
                    alt=""
                    className={`${classes.item} ${classes.img}`}
                />
                <Typography
                    variant="caption"
                    className={classes.item}
                    align='center'>
                    M:N = {props.ratio}
                </Typography>
                <Typography
                    variant="caption"
                    className={classes.item}
                    align='center'>
                    mode = {renameType(props.mode)}
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.item}
                    onClick={handleOpenDosingPump}
                >
                    Edit
                </Button>
            </Grid>

            {dosingPumpOpen && (
                <DosingPumpEditModal
                    open={dosingPumpOpen}
                    handleClose={() => {
                        setDosingPumpOpen(false)
                        props.onRefresh()
                    }}
                    id={props.id}
                />
            )}
        </>
    )
}
