import {
    makeStyles,
    Button,
    Typography,
    Grid
} from "@material-ui/core"
import React, {useState} from "react"
import AddIcon from '@material-ui/icons/Add'
import DeviceControlEditModal from "./modals/DeviceControlEditModal"

const useStyles = makeStyles(() => ({
    item: {
        marginTop: 10,
        marginRight: 10,
        alignItems: 'center',
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
    },

}))

export default function EmptyDeviceControl(props) {
    const [deviceControlOpen, setDeviceControlOpen] = useState(false)

    const classes = useStyles()

    const handleOpenDeviceControl = () => {
        setDeviceControlOpen(true)
    }

    return (
        <>
            <Grid
                container
                justifyContent ="flex-end"
                spacing={0}
                direction="column"
                alignItems="center"
            >
                <Grid>
                    <Button
                        variant="contained"
                        color="info"
                        className={`${classes.item} ${classes.button}`}
                        onClick={handleOpenDeviceControl}
                    >
                        <AddIcon className={classes.icon} />
                    </Button>
                </Grid>
                <Grid>
                    <Typography
                        variant="h6"
                        className={classes.item}
                        align='center'>
                        Add device
                    </Typography>
                </Grid>
            </Grid>

            {deviceControlOpen && (
                <DeviceControlEditModal
                    open={deviceControlOpen}
                    handleClose={() => {
                        setDeviceControlOpen(false)
                        props.onRefresh()
                    }}
                    unit={props.unit}
                    position={props.position}
                    crudType="create"
                />
            )}
        </>
    )
}
