import {
    makeStyles,
    Button,
    Typography,
    Grid,
    IconButton
} from "@material-ui/core"
import React, {useState} from "react"
import EditIcon from '@material-ui/icons/Edit'
import DeviceControlEditModal from "./modals/DeviceControlEditModal"

const useStyles = makeStyles(() => ({
    lineToSeparate: {
        borderBottom: '1px solid #ddd',
    },
    item: {
        marginTop: 10,
        marginRight: 10,
        fontWeight: 'bold',
    },
}))

export default function DeviceControl(props) {
    const [deviceControlOpen, setDeviceControlOpen] = useState(false)

    const classes = useStyles()

    const handleOpenDeviceControl = () => {
        setDeviceControlOpen(true)
    }

    return (
        <>
            <Grid
                container
                justifyContent ="flex-end"
                spacing={0}
                direction="column"
                alignItems="center"
            >
                <Grid>
                    <Typography
                        variant="h4"
                        className={classes.item}
                        align='center'
                        >
                        {props.name}
                        <IconButton size="small" onClick={handleOpenDeviceControl}>
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Typography>
                </Grid>
                <Grid>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.item}
                    >
                        {props.substitute_for_do2}
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.item}
                    >
                        {props.substitute_for_do3}
                    </Button>
                </Grid>
            </Grid>

            {deviceControlOpen && (
                <DeviceControlEditModal
                    open={deviceControlOpen}
                    handleClose={() => {
                        setDeviceControlOpen(false)
                        props.onRefresh()
                    }}
                    id={props.id}
                    name={props.name}
                    substituteForDO2={props.substitute_for_do2}
                    substituteForDO3={props.substitute_for_do3}
                    crudType="update"
                />
            )}
        </>
    )
}
