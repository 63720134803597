import {
    Dialog,
    DialogActions,
    DialogContent,
    TextField,
    Button,
    Typography,
    Grid
} from "@material-ui/core"
import React, {useState} from "react"

export default function EnterPasswordModal(props) {
    const [password, setPasswrd] = useState("")


    const handleSubmit = () => {
        props.onSubmit(password)
        props.handleClose()
    }

    const passwordValidation = () => {
        return password === null || password.length !== 4 || !/^\d+$/.test(password);
    }
    
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="sm"
        >
            <DialogContent dividers={true}>
                <Typography align="center" variant="body2">{props.text_first_line}</Typography>
                <Typography align="center" variant="body2" style={{ marginBottom: '8px' }}>zadaj heslo a stlač {props.action}.</Typography>
                <Typography align="center" variant="body2">{props.text_third_line}</Typography>
                <Typography align="center" variant="body2">type in a password and press {props.action}.</Typography>
                <TextField
                    error={passwordValidation()}
                    value={password}
                    required
                    label="Password"
                    fullWidth
                    onChange={(e) => {
                        setPasswrd(e.target.value)
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justifyContent ="center"
                    spacing={2}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={passwordValidation()}
                            variant="contained"
                            color={passwordValidation() ? 'info' : 'primary'}
                            onClick={handleSubmit}
                        >
                            {props.action}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
