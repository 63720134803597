import AppBar from "@material-ui/core/AppBar"
import Divider from "@material-ui/core/Divider"
import Drawer from "@material-ui/core/Drawer"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles } from "@material-ui/core/styles"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import AlarmIcon from '@material-ui/icons/Alarm'
import BarChartIcon from "@material-ui/icons/BarChart"
import BuildIcon from '@material-ui/icons/Build'
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft"
import ExposureIcon from '@material-ui/icons/Exposure';
import DonutLargeIcon from "@material-ui/icons/DonutLarge"
import ExitToAppIcon from "@material-ui/icons/ExitToApp"
import GroupIcon from "@material-ui/icons/Group"
import ImportExportIcon from "@material-ui/icons/ImportExport"
import ShowChartIcon from '@material-ui/icons/ShowChart';
import MenuIcon from "@material-ui/icons/Menu"
import SnoozeIcon from '@material-ui/icons/Snooze'
import clsx from "clsx"
import { default as React } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import logo from "../assets/logo.jpg"
import { logout } from "../context/auth"

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: "none",
    },
    title: {
        flexGrow: 1,
        marginLeft: 20,
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing(9),
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
    },
    fixedHeight: {
        height: 240,
    },
    logo: {
        border: "5px solid white",
    },
}))

export default function MyBar() {
    const dispatch = useDispatch()
    const isAdmin = useSelector((state) => state.isAdmin)
    const isViewer = useSelector((state) => state.isViewer)
    const user = useSelector((state) => state.user)
    const classes = useStyles()
    const [open, setOpen] = React.useState(true)
    return (
        <>
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, open && classes.appBarShift)}
                color="primary"
            >
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => {
                            setOpen(!open)
                        }}
                        className={clsx(
                            classes.menuButton,
                            open && classes.menuButtonHidden
                        )}
                    >
                        <MenuIcon />
                    </IconButton>
                    <img
                        src={logo}
                        height="32px"
                        alt=""
                        className={classes.logo}
                    />
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        Digital Laboratory 2.0
                    </Typography>
                    <Typography
                        align="right"
                        component="h1"
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                    >
                        {user}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(
                        classes.drawerPaper,
                        !open && classes.drawerPaperClose
                    ),
                }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton
                        onClick={() => {
                            setOpen(!open)
                        }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <List>
                    {isAdmin && (
                        <>
                            <ListItem button component={Link} to="/">
                                <ListItemIcon>
                                    <DonutLargeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Units" />
                            </ListItem>

                            <ListItem button component={Link} to="/users">
                                <ListItemIcon>
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText primary="Users" />
                            </ListItem>

                            <ListItem button component={Link} to="/graphs">
                                <ListItemIcon>
                                    <BarChartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Graphs" />
                            </ListItem>

                            <ListItem button component={Link} to="/difference">
                                <ListItemIcon>
                                    <ExposureIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Difference" />
                            </ListItem>

                            <ListItem button component={Link} to="/material-stock">
                                <ListItemIcon>
                                    <ShowChartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Material stock" />
                            </ListItem>

                            <ListItem button component={Link} to="/alarm-status">
                                <ListItemIcon>
                                    <AlarmIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Alarm status" />
                            </ListItem>

                            <ListItem button component={Link} to="/alarm-silence">
                                <ListItemIcon>
                                    <SnoozeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Alarm silencing" />
                            </ListItem>

                            <ListItem button component={Link} to="/remote-control">
                                <ListItemIcon>
                                    <BuildIcon />
                                </ListItemIcon>
                                <ListItemText primary="Remote control" />
                            </ListItem>

                            <ListItem button component={Link} to="/export">
                                <ListItemIcon>
                                    <ImportExportIcon />
                                </ListItemIcon>
                                <ListItemText primary="Export" />
                            </ListItem>
                        </>
                    )}

                    {isViewer && (
                        <>
                            <ListItem button component={Link} to="/graphs">
                                <ListItemIcon>
                                    <BarChartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Graphs" />
                            </ListItem>
                        </>
                    )}

                    {!isAdmin && !isViewer && (
                        <>
                            <ListItem button component={Link} to="/graphs">
                                <ListItemIcon>
                                    <BarChartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Graphs" />
                            </ListItem>

                            <ListItem button component={Link} to="/difference">
                                <ListItemIcon>
                                    <ExposureIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Difference" />
                            </ListItem>

                            <ListItem button component={Link} to="/material-stock">
                                <ListItemIcon>
                                    <ShowChartIcon />
                                    {/* <BuildIcon /> */}
                                </ListItemIcon>
                                <ListItemText primary="Material stock" />
                            </ListItem>

                            <ListItem button component={Link} to="/alarm-status">
                                <ListItemIcon>
                                    <AlarmIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Alarm status" />
                            </ListItem>

                            <ListItem button component={Link} to="/alarm-silence">
                                <ListItemIcon>
                                    <SnoozeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Alarm silencing" />
                            </ListItem>

                            <ListItem button component={Link} to="/remote-control">
                                <ListItemIcon>
                                    <BuildIcon />
                                </ListItemIcon>
                                <ListItemText primary="Remote control" />
                            </ListItem>
                            
                            <ListItem button component={Link} to="/export">
                                <ListItemIcon>
                                    <ImportExportIcon />
                                </ListItemIcon>
                                <ListItemText primary="Export" />
                            </ListItem>
                        </>
                    )}
                </List>
                <Divider />
                <List>
                    <ListItem
                        button
                        onClick={() => {
                            dispatch(logout())
                        }}
                    >
                        <ListItemIcon>
                            <ExitToAppIcon />
                        </ListItemIcon>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Drawer>
        </>
    )
}
