import {
    FormControl,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Button,
    Box
} from "@material-ui/core"
import React, {useEffect, useState} from "react"
import TopNavSecondary from "../../components/TopNavSecondary"
import {useDispatch, useSelector} from "react-redux"
import {useUnits} from "../../utils/api/units";
import {UNIT_ALL_ID} from "../../utils/api/alarms";
import {setUnit as setUnitID} from "../../context/alert"
import {sortByNameComparator} from "../../utils/functions"
import SignalEditModal from "./modals/SignalEditModal"
import DosingPumps from "./DosingPump/DosingPumps"
import Measurement from "./Measurement/Measurement"
import DeviceControls from "./DeviceControl/DeviceControls"
import {useGetSignalEdit} from "../../utils/api/remote_control";

import {
    remoteControllMode,
    renameType
} from "../../utils/enumMapping"

const useStyles = makeStyles(() => ({
    lineToSeparate: {
        borderBottom: '1px solid #ddd',
    },
    item_top_nav: {
        marginTop: 10,
        marginRight: 10,
        width: "14rem",
    },
    settingsButton: {
        marginLeft: 10,
        marginTop: 25,
        marginRight: 10,
    },
    content: {
        position: 'relative',
        zIndex: 999,
        filter: 'blur(5px)',
        opacity: 0.5,
        pointerEvents: 'none',
    }
}))

export default function RemoteControl() {
    const unitID = useSelector((state) => state.unit)
    const [units, setUnits] = useState([])
    const [unit, setUnit] = useState(unitID)
    const [mode, setMode] = useState(renameType(remoteControllMode.DOSING_PUMP))
    const [signalEditOpen, setSignalEditOpen] = useState(false)
    const [isSignalEditSet, setIsSignalEditSet] = useState(false)

    const dispatch = useDispatch()
    const classes = useStyles()

    useEffect(() => {
        setUnit(unitID === UNIT_ALL_ID ? undefined : unitID)
    },[unitID])

    const getSignalEditRequest = useGetSignalEdit(unit)
    
    useEffect(()=>{
        if (getSignalEditRequest.isSuccess) {
            let signalEditResults = getSignalEditRequest.data.data
            if (signalEditResults.object_id && signalEditResults.pump_and_option_signal_expression && signalEditResults.actual_value_expression) {
                setIsSignalEditSet(true)
            }
            else {
                setIsSignalEditSet(false)
            }
        }
        // eslint-disable-next-line
    },[getSignalEditRequest.data, unit])

    useUnits(
        res=>{
            setUnits([...res.data.results.sort(sortByNameComparator)])
            if (unitID !== UNIT_ALL_ID){
                setUnit(unitID)
            }
            else {
                dispatch(setUnitID(res.data.results[0]))
            }
        }
    )

    const handleUnitChange = (event) => {
        setUnit(event.target.value)
        dispatch(setUnitID(event.target.value))
    }

    const handleOpenSignalEdit = () => {
        setSignalEditOpen(true)
    }

    const handleRefresh = () => {
        getSignalEditRequest.refetch()
    };

    return (
        <>
            <TopNavSecondary
                heading={"Remote control"}
                settingsButton={
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleOpenSignalEdit}
                        className={classes.settingsButton}
                    >
                        Signal Edit
                    </Button>
                }
                buttons={[
                    <FormControl className={classes.item_top_nav} fullWidth>
                        <InputLabel>
                            Current mode:
                        </InputLabel>
                        <Select
                            value={mode}
                            onChange={(e) => {
                                setMode(e.target.value)
                            }}
                            renderValue={(selected) => renameType(selected)}
                        >
                            {Object.entries(remoteControllMode).map(
                                ([key, item]) => {
                                    return (
                                        <MenuItem key={key} value={key} className={classes.lineToSeparate}>
                                            {item}
                                        </MenuItem>
                                    )
                                }
                            )}
                        </Select>
                    </FormControl>,
                    <FormControl className={classes.item_top_nav}>
                        <InputLabel>
                            Unit
                        </InputLabel>
                        <Select
                            value={unit}
                            onChange={handleUnitChange}
                        >
                            {units.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>,
                ]}
            />

            <Box className={isSignalEditSet ? "" : classes.content}>
                {mode === renameType(remoteControllMode.DOSING_PUMP) &&
                    <DosingPumps unit={unit}/>
                }
                {mode === renameType(remoteControllMode.MEASUREMENT) &&
                    <Measurement unit={unit}/>
                }
                {mode === renameType(remoteControllMode.DEVICE_CONTROL) &&
                    <DeviceControls unit={unit}/>
                }
            </Box>

            {signalEditOpen && (
                <SignalEditModal
                    open={signalEditOpen}
                    handleClose={() => {
                        setSignalEditOpen(false)
                    }}
                    unit={unit}
                    onRefresh={handleRefresh}
                />
            )}
        </>
    )
}
