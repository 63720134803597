import {Container, createMuiTheme} from "@material-ui/core"
import {makeStyles, ThemeProvider} from "@material-ui/core/styles"
import React from "react"
import {useSelector} from "react-redux"
import {BrowserRouter, Route, Switch} from "react-router-dom"
import "./App.css"
import MyBar from "./components/AppBar"
import Login from "./features/Login/Login"
import Whoops404 from "./components/NotFoundPage"
import SnackBar from "./components/SnackBar"
import Export from "./features/Export/Export"
import Graphs from "./features/Graphs/Graphs"
import Unit from "./features/Units/Unit/Unit"
import Units from "./features/Units/Units"
import Users from "./features/Users/Users"
import {QueryClient, QueryClientProvider} from "react-query"
import MaterialStock from "./features/MaterialStock/MaterialStock"
import AlarmStatus from "./features/AlarmStatus/AlarmStatus"
import AlarmSilence from "./features/AlarmSilence/AlarmSilence"
import Difference from "./features/Difference/Difference";
import RemoteControl from "./features/RemoteControl/RemoteControl";


const queryClient = new QueryClient()

const theme = createMuiTheme({
    palette: {
        primary: {
            // Purple and green play nicely together.
            main: "#563D7C",
            light: "#BAA9D5",
        },
        // secondary: {
        //   // This is green.A700 as hex.
        //   main: '#CEF9C2',
        // },
    },
})

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: "100vh",
        overflow: "auto",
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeight: {
        height: 240,
    },
}))

function App() {
    const classes = useStyles()
    const user = useSelector((state) => state.user)
    const isAdmin = useSelector((state) => state.isAdmin)

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    {user ? (
                        <div className={classes.root}>
                            <MyBar />
                            <main className={classes.content}>
                                <div className={classes.appBarSpacer} />
                                <Container
                                    maxWidth="lg"
                                    className={classes.container}
                                >
                                    <Switch>
                                        {isAdmin ? (
                                            <Route
                                                exact
                                                path="/"
                                                component={Units}
                                            />
                                        ) : (
                                            <Route
                                                exact
                                                path="/"
                                                component={Graphs}
                                            />
                                        )}
                                        <Route
                                            exact
                                            path="/export"
                                            component={Export}
                                        />
                                        <Route
                                            exact
                                            path="/graphs"
                                            component={Graphs}
                                        />
                                        <Route
                                            exact
                                            path="/difference"
                                            component={Difference}
                                        />
                                        <Route
                                            exact
                                            path="/users"
                                            component={Users}
                                        />
                                        <Route
                                            exact
                                            path="/material-stock"
                                            component={MaterialStock}
                                        />
                                        <Route
                                            exact
                                            path="/alarm-status"
                                            component={AlarmStatus}
                                        />
                                        <Route
                                            exact
                                            path="/alarm-silence"
                                            component={AlarmSilence}
                                        />
                                        <Route
                                            exact
                                            path="/remote-control"
                                            component={RemoteControl}
                                        />
                                        <Route
                                            exact
                                            path="/:id"
                                            component={Unit}
                                        />
                                        <Route path="*" component={Whoops404} />
                                    </Switch>
                                </Container>
                            </main>
                        </div>
                    ) : (
                        <Login />
                    )}
                </QueryClientProvider>
            </BrowserRouter>
            <SnackBar />
        </ThemeProvider>
    )
}

export default App
