import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Box,
} from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import {makeStyles} from "@material-ui/core/styles"
import React, {useEffect, useState} from "react"

import {EXPRESSION, useExpressions} from "../../../utils/api/expressions";
import {useGetSignalEdit, useEditSignalEdit} from "../../../utils/api/remote_control";

const useStyles = makeStyles((theme) => ({
    paper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: 3,
        padding: theme.spacing(2, 4, 3),
    },
    root: {
        "& > *": {
            margin: theme.spacing(1),
        },
    },
    item: {
        marginTop: 10,
        marginRight: 10,
        width: "calc(50% - 16px)",
    },
    lineToSeparate: {
        borderBottom: '1px solid #ddd',
    },
}))

export default function SignalEditModal(props) {
    const classes = useStyles()
    const [confirmationSignalExpression, setConfirmationSignalExpression] = useState("")
    const [pumpAndOptionSignalExpression, setPumpAndOptionSignalExpression] = useState("")
    const [actualValueExpression, setActualValueExpression] = useState("")
    const [pulseExpressions, setPulseExpressions] = useState([])
    const [digitalOrAnalogToDigitalExpressions, setDigitalOrAnalogToDigitalExpressions] = useState([])

    const pulseExpressionsRequest = useExpressions(props.unit, (
        EXPRESSION.PULSE))

    const digitalExpressionsRequest = useExpressions(props.unit, (
        EXPRESSION.DIGITAL))

    const analogToDigitalExpressionsRequest = useExpressions(props.unit, (
        EXPRESSION.ANALOG_TO_DIGITAL))

    const getSignalEditRequest = useGetSignalEdit(props.unit)

    const editSignalEditRequest = useEditSignalEdit()
    
    useEffect(()=>{
        if(pulseExpressionsRequest.isSuccess && digitalExpressionsRequest.isSuccess && analogToDigitalExpressionsRequest.isSuccess){
            let digitalOrAnalogResults = digitalExpressionsRequest.data.data.results
            digitalOrAnalogResults = digitalOrAnalogResults.map((item) => ({
                ...item,
                content_type: 'digitalexpression',
            }));
            digitalOrAnalogResults = digitalOrAnalogResults.concat(
                analogToDigitalExpressionsRequest.data.data.results.map((item) => ({
                    ...item,
                    content_type: 'analogtodigitalexpression',
                }))
            )
            setDigitalOrAnalogToDigitalExpressions(digitalOrAnalogResults)
            let pulseResults = pulseExpressionsRequest.data.data.results
            setPulseExpressions(pulseExpressionsRequest.data.data.results)
            if (getSignalEditRequest.isSuccess) {
                let signalEditResults = getSignalEditRequest.data.data
                setConfirmationSignalExpression(
                    digitalOrAnalogResults.find((x) => x.id === signalEditResults.object_id && x.content_type === signalEditResults.content_type)
                )
                setPumpAndOptionSignalExpression(
                    pulseResults.find((x) => x.id === signalEditResults.pump_and_option_signal_expression)
                )
                setActualValueExpression(
                    pulseResults.find((x) => x.id === signalEditResults.actual_value_expression)
                )
            }
        }
        // eslint-disable-next-line
    },[getSignalEditRequest.data, pulseExpressionsRequest.data, analogToDigitalExpressionsRequest.data, digitalExpressionsRequest.data])

    const expressionValidation = () => {
        return pumpAndOptionSignalExpression && actualValueExpression && confirmationSignalExpression
    }

    const handleSubmit = () => {
        const data = {
            unit: props.unit,
            // confirmation_signal_expression
            content_type: confirmationSignalExpression["content_type"],
            object_id: confirmationSignalExpression["id"],
            pump_and_option_signal_expression: pumpAndOptionSignalExpression["id"],
            actual_value_expression: actualValueExpression["id"],
        }
        editSignalEditRequest.mutateAsync({
            unit_id: props.unit,
            data: data
        }).then(()=>{
            props.handleClose()
            props.onRefresh()
        }).catch((err)=>{
            console.log(err)
        })
    }
    
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="scroll-dialog-title">
                Signal edit
            </DialogTitle>
            <DialogContent dividers={true}>
                <form
                    className={classes.root}
                    onSubmit={handleSubmit}
                >
                    <Box>
                        <FormControl className={classes.item} fullWidth>
                            <InputLabel>Pump and M/N option signal Q*</InputLabel>
                            <Select
                                value={pumpAndOptionSignalExpression}
                                onChange={(e) => {
                                    setPumpAndOptionSignalExpression(e.target.value)
                                }}
                                renderValue={(selected) => selected.name}
                            >
                                {pulseExpressions.map((item, index) => (
                                    <MenuItem key={`expr_${index}`} value={item} className={classes.lineToSeparate}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl className={classes.item} fullWidth required>
                            <InputLabel>Actual value of M/N*</InputLabel>
                            <Select
                                value={actualValueExpression}
                                onChange={(e) => {
                                    setActualValueExpression(e.target.value)
                                }}
                                renderValue={(selected) => selected.name}
                            >
                                {pulseExpressions.map((item, index) => (
                                    <MenuItem key={`expr_${index}`} value={item} className={classes.lineToSeparate}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    <Box>
                        <FormControl className={classes.item} fullWidth>
                            <InputLabel>Confirmation signal F*</InputLabel>
                            <Select
                                value={confirmationSignalExpression}
                                onChange={(e) => {
                                    setConfirmationSignalExpression(e.target.value)
                                }}
                                renderValue={(selected) => selected.name}
                            >
                                {digitalOrAnalogToDigitalExpressions.map((item, index) => (
                                    <MenuItem key={`expr_${index}`} value={item} className={classes.lineToSeparate}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <Grid item>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={props.handleClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={!expressionValidation()}
                            variant="contained"
                            color={!expressionValidation() ? 'info' : 'primary'}
                            onClick={handleSubmit}
                        >
                            Edit
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}
